body {
    font: 14px "Source Code Pro", sans-serif;
    margin: 20px;
    background-color: #292D3E;
}

ol, ul {
    padding-left: 30px;
}

h1 {
    color: #AB47BC;
}

h2, p {
    color: #676E95;
}

.paper {
    padding: 12px;
    background-color: #34324A;
}

a {
    color: #AB47BC;
}